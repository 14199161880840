<template>
  <div service-terms-page>
    <div class="user-terms-con">
      <section>
        <h1>서비스 이용 약관</h1>
        <h2>[1조] 서비스 이용약관 규정의 목적</h2>
        <p>1항) 본 약관은 '빅픽처인터렉티브(주)'(*이하 회사라고 지칭합니다.)에서 제공 및 운영하는 온라인상의 모든 인터넷 서비스(*이하 서비스라고 지칭합니다.)를 통해 회원가입 절차를 완료한 이용자(*회사에서 운영하는 온라인 서비스를 통해 회원가입을 완료한 이용자를 회사는 회원으로 봅니다. 이하 회원으로 지칭합니다.)와 회사 간에 권리, 의무, 책임 사항을 규정함을 목적으로 합니다.</p>
        <h2>[2조] 용어의 정의</h2>
        <p>본 약관에서 사용하는 주요 용어의 정의는 아래와 같습니다.</p>
        <ol>
          <li>1. 서비스 : 회사가 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 'e스포츠' 아레나 및 행사를 이용할 수 있도록 설정한 시스템을 말하며 아울러 서비스를 운영하는 사업자의 의미로도 사용합니다.</li>
          <li>2. 회사 : 서비스를 통해 사업을 영위하는 주체를 말합니다.</li>
          <li>3. 회원 : 회원 가입 절차를 완료하여 회사와 서비스의 이용 계약을 체결한 자를 말합니다.</li>
          <li>4. 이용자 : 회원가입이 완료되지 않은 상태에서 서비스를 이용하는 자를 말합니다.</li>
          <li>5. 이메일 : 정상적으로 사용 가능한 전자 메일 주소이며, 실제 본인 소유의 전자메일 주소를 말합니다.</li>
          <li>6. 비밀번호 : 회원이 부여받은 닉네임, 전화번호 등과 일치된 회원임을 확인하고, 회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.</li>
          <li>7. 온라인 마켓플레이스 : 서비스를 사고팔 수 있는 가상의 온라인 공간을 말합니다.</li>
          <li>8. 온라인 마켓플레이스 제공 사업자 : 서비스 마켓플레이스 및 그 안에서의 부가서비스(광고 서비스 등)를 제공하고, 이에 대한 대가를 받는 사업자 (이하 “회사”라고 합니다)를 말합니다.</li>
          <li>9. 온라인 마켓플레이스 제공 서비스 : 회사가 레벨업지지 사이트와 레벨업지지 코칭을 통하여 회원들에게 제공하는 회원 간의 강의 중개 서비스 및 관련 부가서비스 일체를 말합니다.</li>
          <li>10. 레벨업 코치 : 강의를 판매할 의사로 해당 서비스를 회사가 온라인으로 제공하는 양식에 맞추어 회사의 레벨업지지 사이트 또는 레벨업지지 코치에 등록하거나 관련 정보를 프로필에 등록한 회원을 의미합니다.</li>
          <li>11. 수강생 : 레벨업 코치의 서비스를 구매한 회원을 말합니다.</li>
          <li>12. 레벨업 포인트 : 수강생이 서비스를 구매하고 그 대가를 지급하는 데 사용하기 위한 것으로 회사가 발행, 관리하는 선불전자지급수단을 말합니다. 레벨업 포인트는 2가지 성격으로 구분됩니다.
            <ul>
              <li>① 회원이 유상으로 구매하는 ‘UP 포인트’</li>
              <li>② 회사가 무상으로 지급하는 ‘VEL 포인트'</li>
            </ul>
          </li>
          <li>13. 레벨업지지 안전결제서비스 : 레벨업 코치와 수강생의 사이에 신뢰할 수 있는 중립적인 회사가 중개하여 금전 또는 물품(서비스 포함)을 거래를 하도록 하는 에스크로 방식의 서비스를 말합니다.</li>
          <li>14. 외부거래 : 레벨업 안전결제서비스를 통하지 않고 레벨업 코치와 수강생이 직접 서비스 대금을 주고받는 행위를 말합니다.</li>
          <li>15. 수익금 : 레벨업 코치가 중개 서비스를 이용하여 판매 완료한 금액을 의미합니다. 수강생의 결제 대금 중 판매 수수료를 제외한 금액으로 지급됩니다.</li>
          <li>16. 닉네임 : 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 문자나 숫자 혹은 그 조합으로 서비스 이용 시 회원을 구분하고 지칭하고 나타내는 명칭을 말합니다.</li>
          <li>17. 이용 중지 : 회사가 약관에 따라 회원의 서비스 이용을 제한하는 것을 말합니다.</li>
          <li>18. 회원탈퇴 : 회사 또는 회원이 서비스 사용 후 이용 계약을 해약하는 것을 말합니다.</li>
          <li>19. 서비스 정책: 개인정보처리방침과 서비스 이용약관의 통칭입니다.</li>
        </ol>
        <h2>[3조] 약관의 명시와 개정</h2>
        <ol>
          <li>1항) 회사는 본 약관의 내용과 상호, 영업장 소재지 주소, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등), 개인정보 관리책임자 등을 회원이 알 수 있도록 서비스 홈페이지에 게시합니다.</li>
          <li>2항) 회사는 본 약관에 명시되지 아니한 사항에 대해서는 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정을 준수합니다.</li>
          <li>3항) 회사가 약관을 개정할 때는 적용 일자 및 개정 사유를 명시하여 회사 영업일 기준으로 적용 일자 7일 이전부터 적용 일자 직전일까지 공지합니다. 다만, 회원에게 불리하게 적용될 수 있는 약관 내용은 최소 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 이용자가 개정된 약관의 내용을 알기 쉽도록 명확하게 구분 표시해야 하는 의무를 갖습니다.</li>
          <li>4항) 회사는 서비스 내 공지를 통해 개정 약관에 관한 공지의 의무를 다하였음에도 개정된 약관 시행일에서 7일이 지난 후까지 회원의 특별한 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 회원이 개정된 약관에 대해서 거부 의사가 있으면 이용계약을 해지할 수 있습니다.</li>
          <li>5항) 개정 약관조항은 개정 약관의 적용 일자 이후에 체결된 계약만 적용되는 것을 원칙으로 합니다. 다만, 회사가 지정하는 방식에 따라 회원이 동의한 경우에는 개정약관 조항이 적용됩니다.</li>
          <li>6항) 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다. 단, 회원이 개정 약관에 동의하지 아니할 때는 회원은 서비스에 대하여 회원의 탈퇴 및 기타 이용계약을 해지할 수 있습니다.</li>
          <li>7항) 본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 소비자 보호에 관한 법률, 약관의 규제에 관한 법률 및 관계 법령 또는 상관례를 준수합니다.</li>
        </ol>
        <h2>[4조] 서비스의 제공, 제공 목적의 변경</h2>
        <ol>
          <li>1항) 회사는 회원에게 아래 '각' 호에서 명시한 서비스를 제공합니다.
            <ul>
              <li>1호) 'e스포츠' 아레나의 의뢰 및 아레나 참여와 관련된 중개역할</li>
              <li>2호) 기타 회사가 정하는 모든 업무 및 서비스에 필요하다고 판단된 내부 유/무료 서비스</li>
              <li> 3호) 중개 서비스: 회사가 레벨업지지 사이트를 통하여 회원 상호간에 물품매매거래가 이루어질 수 있는 사이버 거래장소를 온라인으로 제공하는 서비스 및 관련 부가서비스 일체를 말합니다. 회사는 전문가의 물품판매의 촉진을 위한 관련 부가서비스로서 전문가의 상품노출을 위한 전시권 등 판매촉진서비스를 유상으로 제공할 수 있습니다.</li>
            </ul>
          </li>
          <li>2항) 회사는 기술적 사양의 변경 등의 사유가 발생하면 제공 서비스의 내용을 변경할 수 있으며, 기타 제휴사의 사정(* 고의나 과실로 서비스 사이트에 업데이트가 이루어지지 않는 것을 포함하나 이에 한하지 않음)으로 서비스 중단이 불가피한 사정이라면 해당 서비스를 부분 또는 전체 중단할 수 있습니다. 위 각 경우에는 변경된 내용 및 제공 일자를 명시하여 즉시 공지합니다.</li>
          <li>3항) 이용자와 계약을 체결한 서비스의 내용을 특정 사유로 변경하거나 중단할 때는 그 사유를 이용자에게 통지 가능한 연락처(전자우편, 전화 등)로 즉시 통지합니다.</li>
        </ol>
        <h2>[5조] 서비스 이용의 제한, 일시적 중지 및 종료</h2>
        <ol>
          <li>1항) 회사는 아래 각 '호'에서 명시한 사유가 발생하는 경우 회원의 서비스 이용을 제한하는 조치를 취할 수 있으며, 회원의 개인 이메일 또는 서비스 내 공지를 통해 통보합니다.
            <ul>
              <li>1호) 컴퓨터 등 정보통신설비의 보수점검 교체 및 고장</li>
              <li>2호) 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지하는 등 통신의 두절</li>
              <li>3호) 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 경우</li>
              <li>4호) 회사 판단하에 특정 사유로 서비스 제공을 지속하는 것이 불가능하다고 인정하는 경우</li>
              <li>5호) 국가비상사태 및 천재지변으로 회사가 물리적 피해를 받아 서비스 제공이 어려운 경우</li>
              <li>6호) 이용약관에 따라 제공되기로 약속한 서비스 제공이 더는 어렵다고 회사가 판단한 경우</li>
            </ul>
          </li>
          <li>② 제1항에 의한 서비스 중지의 경우에는 “회사”는 제9조에 정한 방법으로 이용자에게 통지합니다.</li>
        </ol>
        <h2>[6조] 서비스 회원가입과 회원가입불가</h2>
        <ol>
          <li>1항) 이용자는 회사가 정한 회원가입 양식에 따라 회원정보를 기재한 후 본 서비스이용약관과 개인정보처리방침에 동의 버튼을 체크를 완료함으로써 회원가입을 신청할 수 있습니다. 회사는 이용자가 서비스이용약관과 개인정보처리방침에 동의 버튼을 클릭한 행위를 서비스 정책을 모두 읽고 숙지 및 동의한 것으로 간주합니다.</li>
          <li>2항) 이용자가 회원가입 절차를 완료함으로써 회원가입이 완료되며 회원이 됩니다. 다만, 아래 각 '호'에 해당하는 사항은 회사는 회원가입 신청을 거부하거나 기존 승인된 회원 자격을 취소할 수 있습니다.
            <ul>
              <li>1호) 본인의 실명으로 신청하지 않은 경우</li>
              <li>2호) 휴대폰 인증, 본인 인증이 불가능한 경우</li>
              <li>3호) 다른 사람의 명의를 사용하여 신청한 경우</li>
              <li>4호) 이용 신청 시 필요 사항을 허위로 기재하여 신청한 경우</li>
              <li>5호) 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
              <li>6호) 사회 미풍양속을 저해할 목적으로 신청한 경우</li>
              <li>7호) 14세 미만 아동, 법정대리인의 동의 여부와 관련 없이 회사가 제공하는 모든 서비스의 회원가입 불가</li>
              <li>8호) 회사가 정한 회원가입 신청 요건에 미비하거나 서비스의 기술 및 품질에 지장을 줄 수 있다고 회사가 판단한 경우</li>
            </ul>
          </li>
          <li>3항) 회사와 회원 간의 관계가 공식적으로 성립된 시기는 회사 또는 회사가 제공하는 서비스로부터 회원가입 시 작성된 인증된 회원 개인 전자우편으로 가입 축하 이메일을 받은 시점과 회원 가입 시 기재한 ID 및 비밀번호로 서비스에 로그인이 정상적으로 작동한 시점으로 봅니다.</li>
        </ol>
        <h2>[7조] 휴면계정의 전환, 회원의 탈퇴, 회원자격상실</h2>
        <ol>
          <li>1항) 회사는 회원이 서비스에 1년 동안 로그인 및 서비스 이용 기록이 확인되지 않는다고 판단한 경우 회원자격을 휴면 계정으로 전환합니다.
            <ul>
              <li>1호) 휴면계정 전환 회원이 서비스를 이용하기 위해서는 본인인증 후, 회사가 정한 절차에 따라 휴면 상태를 해제해야 합니다.</li>
              <li>2호) 회사는 휴면계정으로 전환된 회원이 휴면으로 전환된 시점 일로부터 3년 동안 로그인 및 서비스 이용 기록이 확인되지 않는다고 판단하면 해당 회원의 개인 정보와 회원이 작성한 모든 게시물 및 서비스 활동 데이터를 삭제할 수 있습니다</li>
            </ul>
          </li>
          <li>2항) 사망 회원은 가족관계증명서 원본으로 증명된 사망 회원의 가족 요청이 있으면 회사는 해당 회원의 개인정보와 서비스 내 모든 게시물(* 공유된 게시물 포함)과 활동 데이터를 삭제할 수 있습니다. 다만, 가족은 해당 회원이 사망하였음을 증명할 수 있는 사망증명서 원본을 회사에 제공해야 합니다.</li>
          <li>3항) 회원은 서비스 정책에 관해서 반대의사가 있는 경우 언제든지 서비스 내 회원정보관리를 통해서 회사가 정한 절차에 따라 회원 자격 해지 및 탈퇴할 수 있으며, 회사는 회원의 탈퇴 신청을 즉시 수락합니다.</li>
          <li>4항) 회사 또는 회사가 운영하는 서비스는 아래 각 '호'에 해당하면 별도의 통보나 공지 없이 해당 회원의 자격을 박탈할 수 있는 권한이 있습니다.
            <ul>
              <li>1호) 본인의 회원 정보를 실명으로 신청하지 않고, 허위 내용을 작성한 경우</li>
              <li>2호) 타인의 명의를 부당한 방법으로 사용한 경우</li>
              <li>3호) 타 회원에게 피해를 주거나 기타 사회 미풍양속을 현저히 저해하는 행위를 한 경우</li>
              <li>4호) 회원 탈퇴, 재가입을 반복하여 회사가 제공하는 혜택 등을 편법으로 복수 수취한 경우</li>
              <li>5호) 회원 자격이 정지된 이후 회사 규정에 따라 회원자격이 다시 복귀하였는데, 회원 자격 상실 사유에 해당하는 행위를 다시 반복하였다고 판단된 경우</li>
              <li>6호) 회사 또는 서비스의 허위사실을 유포하여 사업적 손해 또는 명예를 실추시킨 경우</li>
              <li>7호) 서비스가 제공하는 정보를 변경하거나 수정하는 행위로 서비스의 정상적인 운영을 방해하는 경우</li>
              <li>8호) 회사와 서비스가 제공하는 모든 저작권과 지적재산권을 침해하거나 침해하려고 시도한 경우</li>
              <li>9호) 기타 회사가 회원으로서 자격을 지속시키기에 타 회원에게 피해를 줄 수 있을 것이라고 판단한 경우</li>
            </ul>
          </li>
        </ol>
        <h2>[8조] 회원에 대한 통지의 의무</h2>
        <ol>
          <li>1항) 회사는 회원과 기존 합의된 서비스 내용에서 변경, 취소, 신규 서비스의 개시 등의 사유가 발생하면 회원이 회원정보에 등록한 전자우편 이메일을 통하여 해당 내용을 모두 통보하는 의무가 있습니다</li>
          <li>2항) 회사는 회원 다수에게 통지해야 하는 사유가 발생하는 경우 회사 또는 회사가 제공하는 서비스 내 공지사항을 통해 통지합니다. 단, 회원에게 중대한 영향을 미치는 사항이라면 회원의 개인 전자우편 주소로 개별 통지해야 하는 의무가 있습니다.</li>
        </ol>
        <h2>[9조] 회사의 의무</h2>
        <ol>
          <li>1항) 회사는 회원 각 개인의 개인정보 보호를 위해 물리적 보안 장비 설치와 인재 예방을 위한 보안 정책을 수립하여 운영합니다.</li>
          <li>2항) 회사는 안정적인 서비스 제공과 개인 정보 보호를 위해 수립한 보안 장비 설치와 정책 수립을 특정 기간을 두고 매번 점검하며 개선합니다.</li>
          <li>3항) 회사는 대한민국 사회의 일반적 도덕 및 공공질서에 반하는 행위를 하지 않습니다.</li>
          <li>4항) 회사는 회원 본인의 승낙 없이 제 삼자에게 개인정보를 누설 및 배포하지 않습니다.</li>
          <li>5항) 회사는 제 삼자와의 서비스 제공계약 등을 체결하여 회원에게 서비스를 제공하는 경우 회원 본인에게 명시적인 동의를 받은 후 제 삼자에게 제공되는 회원정보를 정확하게 명시합니다.</li>
          <li>6항) 회사는 회원이 동의하지 않은 영리 목적의 전자우편 이메일, SMS 문자메시지 등을 발송하지 않습니다.</li>
          <li>7항) 회사는 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 온 힘을 다합니다.</li>
        </ol>
        <h2>[10조] 회원의 의무</h2>
        <ol>
          <li>1항) 회원은 회원가입 또는 회원 정보 변경 시 허위 내용을 등록하여서는 안 됩니다.</li>
          <li>2항) 회원은 회사가 제공하는 서비스의 정보를 변경할 수 없으며, 회사의 승낙 없이 어떠한 목적으로도 방송, 복제, 출판 등에 사용하거나 제 삼자에게 판매 제공할 수 없습니다.</li>
          <li>3항) 회원은 회원 개인 계정 ID와 비밀번호에 관해 관리에 관한 책임을 가지며, 제 삼자에게 제공할 수 없습니다. 만약, 이를 위반하여 발생하는 모든 손해에 대해서는 회사가 책임을 부담하지 않습니다.</li>
          <li>4항) 회원이 회원 개인 계정 ID와 비밀번호를 분실하였거나 제 삼자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하여 회사의 안내를 받아야 합니다.</li>
          <li>5항) 회원은 제 삼자에 대한 허위 사실 개재 및 권리를 침해하는 행위, 지적재산권 침해, 회사에 대한 비방을 할 수 없습니다.</li>
          <li>6항) 회원은 타 회원의 개인 계정을 도용하는 행위를 할 수 없습니다.</li>
          <li>7항) 회원은 타 회원, 제 삼자의 금융 정보를 이용하여 유료 서비스를 이용할 수 없습니다.</li>
          <li>8항) 회원은 외설적이고 폭력적인 내용이 담겨 있는 게시물을 작성하거나 타 회원에게 메일을 통하여 보내는 행위를 할 수 없습니다.</li>
          <li>9항) 회원은 회사가 제공하는 서비스를 개작과 같은 행위를 할 수 없습니다.</li>
          <li>10항) 회원은 타 회원을 대상으로 한 욕설, 비방, 허위 사실 유포, 스토킹과 같은 행위를 할 수 없습니다.</li>
          <li>11항) 회원은 회사가 제공하는 서비스를 대상으로 소프트웨어, 하드웨어, 기타 전기통신 장비의 정상적인 가동 방해 또는 파괴를 목적으로 하는 모든 행위를 해서는 안 됩니다.</li>
          <li>12항) 회원은 회사의 직원으로 속여서는 안됩니다.</li>
          <li>13항) 회원은 대한민국 청소년보호법에 규정하는 청소년유해 매체물과 정보통신망법 등 관련 법령에 따라 금지되는 정보, 기타 공공질서 또는 미풍양속을 위배하는 게시물 등을 작성, 게시, 유포하는 행위를 할 수 없습니다.</li>
          <li>14항) 회원은 회사가 정한 서비스 이용약관 및 개인정보처리방침, 서비스와 관련한 현행 법령을 위반하여서는 안 됩니다.</li>
          <li>15항) 회원은 회사가 제공하는 서비스 중 성인인증이 필요한 경우 회사가 정한 절차에 따라 본인의 추가 회원정보를 회사에 제공해야 합니다.</li>
          <li>16항) 회원은 기타 공서양속에 반하는 정보를 공개 또는 개시하는 행위를 할 수 없습니다.</li>
        </ol>
        <h2>[11조] 양도금지</h2>
        <ol>
          <li>1항) 회원은 회사로부터 받은 서비스 이용에 대한 권리를 타 회원 또는 제 삼자에게 재산권, 질권 및 특정 목적을 두고 양도나 증여 행위를 할 수 없습니다.</li>
        </ol>
        <h2>[12조] 서비스 이용의 범위와 정보의 제공</h2>
        <ol>
          <li>1항) 회원은 회원가입 절차를 완료하면 회사가 제공하는 서비스를 이용할 수 있습니다.다만, 본인 인증과 회원등급에 따라 일부 서비스 이용이 제한될 수 있으며, 유료 서비스를 이용하는 경우 회사 영업일 기준으로 요금납부 여부가 확인된 직후부터 이용 가능합니다.</li>
          <li>2항) 미성년자 회원이 회사가 제공하는 유료 서비스를 이용하는 경우 법정대리인의 동의절차를 얻어야 합니다.</li>
          <li>3항) 회원이 서비스를 이용하는 서비스 이용시간은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사가 서비스 제공이 불가능하다고 판단된 경우 또는 서비스 정기점검 등의 사유가 발생하여 전체 서비스 중 일부를 구분할 필요가 있다고 판단된 경우에는 별도 날짜와 시간을 정하여 제공할 수 있습니다.</li>
          <li>4항) 회사는 서비스 이용에 관한 정보를 회원 개인 전자우편 또는 SMS 등의 방법으로 제공할 수 있으며, 회원은 수신을 거부할 수 있습니다.</li>
          <li>5항) 회원이 전항에 의해 수신을 거부하는 경우, 서비스 이용에 필수적으로 요구되는 정보(예: 관련 규정, 정책의 변경)를 서비스 공지사항을 통해 공시하는 방법으로 갈음할 수 있습니다. 다만, 수신 거부한 회원이 서비스 홈페이지 공지사항을 확인하지 않아 발생하는 손해에 대해서는 회사는 책임을 부담하지 않습니다.</li>
        </ol>
        <h2>[13조] 손해배상</h2>
        <ol>
          <li>1항) 회사는 무료로 제공하는 본 서비스의 이용과 관련하여 회원에게 발생한 손해에 관하여서 책임을 지지 않습니다. 다만, 회사의 귀책사유로 발생한 회원의 피해에 대하여서는 회원은 회사에 이의제기할 수 있습니다.</li>
        </ol>
        <h2>[14조] 분쟁의 해결</h2>
        <ol>
          <li>1항) 회원은 회사 간에 분쟁이 발생하면 민사소송법상 회사 사업장이 소재된 관할 법원에서 법리적으로 해결합니다.</li>
        </ol>
        <h2>[15조] 청소년 보호</h2>
        <ol>
          <li>1항) 회사는 대한민국 정보통신망법에서 정한 청소년보호정책을 준수하며 회사가 제공하는 서비스 내 청소년 유해 정보가 난립하지 않도록 별도 인원을 통해 관리 운영합니다.</li>
        </ol>
        <h2>[16조] 면책조항</h2>
        <ol>
          <li>1항) 회사는 천재지변과 같은 불가항력으로 인해 서비스 제공을 하지 못한 경우 면책됩니다.</li>
          <li>2항) 회사는 회원의 귀책사유 때문에 발생한 서비스 이용 장애에 관하여서는 그 책임이 면책됩니다.</li>
          <li>3항) 회사는 회원이 게재한 정보에 관하여서는 그 책임을 지지 않고 면책됩니다.</li>
          <li>4항) 회사는 서비스 이용과 관련 회원의 고의, 과실에 의해 발생한 손해에 대하여서는 면책됩니다.</li>
          <li>5항) 회사는 회사의 귀책사유 외 발생한 회원의 손해에 관하여서는 책임을 지지 않고 면책됩니다.</li>
        </ol>
        <h2>[17조] 관련법령 규정의 준용</h2>
        <ol>
          <li>1항) 본 약관에 명시되지 않은 사항에 대해서는 대한민국 관련 법령을 준수하고, 법에 명시되지 않은 부분에 대하여는 대한민국 상/관습을 준수합니다.</li>
        </ol>
        <h2>[18조] 관련법령과의 관계</h2>
        <ol>
          <li>1항) 회사는 수수료(제29조의 판매서비스 이용료 등 회사가 전문가에게 서비스를 제공한 대가로 전문가로부터 받는 일체의 금원을 말함) 및 판매촉진 서비스이용료의 결정과 관련하여 다른 온라인 마켓 플레이스(오픈마켓 형태의 모든 서비스 포함) 사업자와 담합 등 불공정행위를 하지 않습니다.</li>
          <li>2항) 회사는 전문가에게 가격 인하, 기획전 참여 등을 부당하게 강요하지 않습니다.</li>
          <li>3항) 회사는 전문가가 다른 온라인 마켓플레이스(오픈마켓 형태의 모든 서비스 포함) 사업자와 거래하지 못하게 하거나 다른 온라인 마켓플레이스(오픈마켓 형태의 모든 서비스 포함) 사업자와 거래한다는 이유로 전문가에게 불이익을 제공하지 아니합니다.</li>
        </ol>

        <h2>[19조] 예치금</h2>
        <ol>
          <li>1항) 수강생은 레벨업지지 포인트충전 서비스를 이용하여 일정 금액을 예치할 수 있습니다.</li>
          <li>2항) 레벨업지지 포인트는 수강생의 서비스구매와 관련한 결제의 수단으로만 사용이 가능하며 레벨업지지 코치의 광고결제의 수단으로의 사용은 불가능합니다.</li>
          <li>3항) 충전 포인트는 예치금 형태로, 충전 시점의 세금계산서 발행은 불가능합니다. 충전 단계에서 회원의 요청이 있는 경우 현금영수증 지출 증빙용의 발급은 가능하나 실제 용역 주체가 아닌 중개업체 명의로 발행됨으로 영수증 이외의 용도로 사용이 불가능합니다.</li>
          <li>4항) 충전 포인트(예치금)의 환급은 예치금 결제 시 이용한 동일 결제수단으로 환불 처리합니다, 회사는 예치금의 신뢰도 확인과 관련된 사항, 사용상의 제한이나 그 밖의 주의사항을 서비스 화면에 공지하거나 서비스를 이용하는 회원에게 레벨업지지 안내 등을 활용하여 고지합니다.</li>
        </ol>
        <h2>[20조] 레벨업지지 UP포인트</h2>
        <ol>
          <li>1항) 레벨업지지 UP포인트는 수강생이 서비스를 구매하고 그 대가를 지급하는 데 사용하기 위한 결제 수단으로, 회사가 발행·관리하는 선불전자지급수단을 말합니다.</li>
          <li>2항) 회사의 결제 시스템을 통해 레벨업지지코치의 서비스를 구매하는 경우, 회원은 단독으로 또는 다른 결제 수단과 함께 레벨업지지 UP포인트을 사용할 수 있으며 1UP 포인트는 현금 10원의 의미가 있습니다.</li>
          <li>3항) 회원의 서비스 구매·이벤트 참여 시 회사는 정책에 따라 레벨업지지 UP포인트을 무상으로 적립해줄 수 있습니다.</li>
          <li>4항) 회원이 적립이나 이벤트 등을 통하여 회사로부터 무상으로 제공받은 레벨업지지 UP포인트는 회사에서 정한 기한 내에만 이용이 가능합니다.</li>
          <li>5항) 무상으로 적립된 레벨업지지 UP포인트을 통해 서비스를 구매한 경우, 현금영수증은 발행되지 않습니다.</li>
          <li>6항) 회원은 필요한 경우 다양한 결제 수단을 이용하여 레벨업지지 UP포인트를 유상으로 충전하여 사용할 수 있습니다.</li>
          <li>7항) 레벨업지지 UP포인트는 회사에서 제공하는 단위 금액 및 결제 수단을 통해서만 충전할 수 있습니다.</li>
          <li>8항) 충전된 레벨업지지 UP포인트의 유효기간은 최초 충전 일로부터 5년이며, 거래 취소에 의해 레벨업지지 UP포인트로 반환된 결제 대금 역시 최초 결제일로부터 기산합니다. 회사는 소진일 30일 전에 소멸 예정 사실을 회원에게 이메일(E-mail) 등으로 통지하며, 유효기간의 연장은 불가합니다.</li>
          <li>9항) 회원은 유상으로 충전하여 보유하고 있는 충전 UP포인트에 대하여 유효 기간 내에 관리자에게 문의 하여 포인트 환불, 환급 신청을 할 수 있습니다. 단, 무상으로 적립된 UP포인트는 환급의 대상이 되지 않습니다.</li>
          <li>10항) 충전 UP포인트는 환급 가능한 전액을 대상으로 신청 가능하며, 회사는 신청이 있는 날부터 7일 이내 원 결제 수단으로 환급함을 원칙으로 합니다. 다만, 원 결제 수단으로의 환급이 불가할 경우 기타 환급 수단으로 지급할 수 있습니다.</li>
          <li>11항) 제10항에도 불구하고, 본 약관 22조 라.의 2.거래 부정행위에 해당하는 거래 부정행위가 의심되는 경우 회사는 해당 내역을 확인하기 위하여 최대 120일까지 충전 UP포인트의 환급을 보류할 수 있습니다. 이 경우, 회원이 거래 사실 증빙서류 등 관련 서류를 회사에 제공하는 때에는 회사가 이를 확인한 후 송금처리합니다.</li>
          <li>12항) 본 조에서 정한 것 외에도 법률의 규정이나 합리적인 사유(법원·수사기관·금융기관 등 권한 있는 기관의 요청이 있는 경우, 기타 위탁사를 통해 사실 확인이 필요한 경우 등)가 있으면, 회사는 해당 사항을 회원에게 통지하고 처리 결과의 확인 시까지 거래 대금의 전부 또는 일부의 지급을 추가로 유보하거나 상계할 수 있습니다.</li>
          <li>13항) 회사는 회원에게 환급 수단·계좌와 관련하여 필요한 자료의 제공을 요구할 수 있으며, 회원은 이에 협조하여야 합니다.</li>
          <li>14항) 회사는 천재지변 및 레벨업지지 UP포인트의 결함 등의 사유로 재화 또는 용역을 제공하기 곤란하여 회원이 레벨업지지 UP포인트을 사용하지 못하게 된 경우, 충전된 레벨업지지 UP포인트의 잔액의 전부를 지급합니다.</li>
          <li>15항) 다음 각호에 해당하는 경우 레벨업지지 UP포인트가 소멸됩니다.
            <ul>
              <li>① 레벨업지지 UP포인트의 유효기간이 경과하는 경우</li>
              <li>② 회원이 서비스의 이용계약 해지와 함께 포인트 소멸에 동의한 경우</li>
              <li>③ 무상의 레벨업지지 UP포인트 지급을 목적으로 회사에서 제시한 조건이 멸실되는 경우</li>
            </ul>
          </li>
          <li>16항) 회원은 레벨업지지 UP포인트를 본인의 거래에 대해서만 사용할 수 있으며, 어떠한 경우라도 타인에게 매매 또는 양도하거나, 실질적으로 매매 또는 양도와 동일하게 볼 수 있는 행위를 하여서는 아니 됩니다</li>
          <li>17항) 회원이 부당 또는 부정하게 레벨업지지 UP포인트를 취득한 경우 회원은 레벨업지지 UP포인트를 사용할 수 없으며 회사는 이를 회수할 수 있습니다. 회사는 본 약관 22조 라.의 3.결제부정행위에 준하는 행위가 의심되는 경우, 위반한 회원에 대하여 이용계약의 해지, 적립금 또는 할인쿠폰의 환수, 회원 자격의 제한, 현금 환불 보류 등의 조치 및 민∙형사 상의 법적 조치 등을 취할 수 있습니다.</li>
          <li>18항) 회원의 구매 취소 등의 사유가 발생하여 회사가 회원에게 이미 부여한 레벨업지지 포인트를 환수하고자 할 경우, 환수 시점에 해당 회원의 포인트 잔액이 환수 포인트보다 작으면 회사는 해당 회원에 대한 레벨업지지 포인트를 0보다 작은 마이너스 포인트로 처리할 수 있으며, 회원은 자신의 마이너스 포인트를 ‘추가 서비스 구매를 통한 포인트 적립’, ‘현금 결제를 통한 포인트 충전’ 등 회사가 요구하는 방법으로 만회해야 합니다.</li>
          <li>19항) 레벨업지지 포인트의 부여 및 사용에 관한 상세한 사항은 회사가 정한 정책에 따르며, 회사는 구체적인 사항을 서비스 페이지를 통해 공지합니다.</li>
          <li>20항) 회사는 사정에 따라 레벨업지지 포인트의 적립률과 유효기간 등의 제반규칙을 변경할 수 있습니다.</li>
        </ol>
        <h2>[21조] 서비스이용료</h2>
        <ol>
          <li>1항) 회사가 부과하는 서비스이용료는 서비스의 광고효과 및 회사가 제공하는 시스템이용료로서 판매 서비스이용료, 상위노출광고서비스이용료로 구분됩니다.</li>
          <li>2항) 판매서비스이용료
            <ul>
              <li>① 판매서비스이용료는 최종 구매 확정 금액 및 카테고리, 프로모션에 따라 차등 적용되어 레벨업지지 코치에게 부과됩니다.</li>
              <li>② 판매서비스이용료는 수강생의 구매 확정시 수익금 전환단계에서 공제되며, 수강생의 구매확정 또는 자동구매확정으로 정산된 수수료는 반환하지 않습니다. 다만, 주문 취소의 경우 판매서비스이용료는 부과되지 않습니다.</li>
            </ul>
          </li>
          <li>3항) 상위노출광고서비스이용료
            <ul>
              <li>① 광고성 부가서비스에 관한 서비스이용료인 상위노출광고 서비스이용료는 거래 여부와 관계없이 선불로 부과되며 환불되지 않습니다. 회사가 제공하는 서비스 또는 정보통신시스템의 하자로 인하여 광고등록이 정상적으로 이루어지지 않을 경우 환불합니다.</li>
              <li>② 상위노출광고 서비스이용료는 당해 서비스가 제공되는 웹페이지의 서비스화면에서 과금기준, 결제방식 등을 표시합니다.</li>
            </ul>
          </li>
          <li>4항) 회사는 필요한 경우 서비스이용료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 회사가 제공하는 서비스화면을 통하여 공지합니다. 회사는 판매 활성화 등을 위하여 서비스이용료 범위 내에서 특정 서비스에 대한 서비스이용료를 할인할 수 있습니다. 또한 회사는 특정 레벨업지지 코치에 대한 서비스이용료를 서비스의 성격, 판매실적, 회원의 특성 등 회사가 정하는 일정한 기준과 절차에 따라 조정 또는 면제할 수 있습니다. 특정 레벨업지 지코치에 대한 서비스이용료의 조정 또는 면제 시 회사는 미리 그에 관한 주요 사항을 대상자에게 고지 또는 약정할 수 있습니다.</li>
          <li>5항) 회사는 구매확정 처리가 완료된 거래 건에 한하여 당 월 서비스이용료에 대한 세금계산서를 익월 초 일괄 발행합니다.</li>
        </ol>
        <h2>[22조] 중개서비스 이용정지, 이용제한</h2>
        <ol>
          <li>가. 회사는 중개 서비스를 통한 거래의 안전성과 신뢰성을 위하여 이 약관에서 정한 바에 따라 회원자격을 정지하거나 서비스의 이용 제한, 기타 필요한 조치를 취할 수 있습니다. 회원자격이 정지된 회원은 중개서비스를 이용할 수 없으며, 진행중인 판매/구매관리 서비스를 제외한 모든 서비스를 이용할 수 없습니다.</li>

          <li>나. 회사가 회원자격을 정지하거나 판매/구매서비스의 이용 제한, 기타 필요한 조치를 취할 경우 이 약관에 특별한 규정이 없는 한 사전에 회원에게 유선 또는 메일(e-mail), 기타의 방법을 통하여 통보합니다. 다만, 회원의 연락이 두절되거나 긴급을 필요로 하는 것과 같이 부득이한 경우 선 조치 후 통보할 수 있습니다. 회원은 회사의 조치에 대해 이의를 제기할 수 있으며, 회사는 회원의 이의제기가 타당하다고 판단되는 경우 조치를 해제할 수 있습니다.</li>

          <li>다. 회원이 이용정지 중에 이용계약을 임의해지 하거나 다른 계정을 통해 재이용을 신청한 것이 확인되면 이 약관 제9조에 기하여 이용신청에 대한 승낙을 거부할 수 있습니다.</li>

          <li>라. 회원은 이용중에 다음 각호의 행위를 할 수 없습니다.</li>
          <li> 1. 외부거래
            <ul>
              <li>- 회사가 제공하는 안전결제서비스를 이용하지 않고, 외부에서 거래하는 행위</li>
              <li>- 거래 전 연락처를 공유하거나 대면상담을 요청·진행하는 행위</li>
              <li>- 기타 혜택 제안 등을 통해 외부 거래를 유도하는 행위</li>
              <li>- 위의 각호의 행위에 준하는 일체의 외부 거래 유도 행위</li>
            </ul>
          </li>
          <li>2. 거래 부정행위
            <ul>
              <li>- 무재고 판매·재판매 행위</li>
              <li>- 재판매 목적의 구매 행위</li>
              <li>- 거래 의사 없이 구매·취소를 반복하는 행위</li>
              <li>- 판매데이터를 임의로 조작하는 등 부정한 목적으로 거래하는 행위</li>
              <li>- 위의 각호의 행위에 준하는 일체의 거래 부정행위</li>
            </ul>
          </li>
          <li>3. 결제 부정행위
            <ul>
              <li>- 레벨업지지의 결제수단만을 이용하기 위한 결제 행위</li>
              <li>- 결제 수단의 현금화 행위</li>
              <li>- 부정한 목적의 무상 혜택 사용 행위(레벨업지지 포인트, 쿠폰, 마일리지 등)</li>
              <li>- 부정한 목적의 분할 결제 행위</li>
              <li>- 결제 수단의 명의도용</li>
              <li>- 위의 각호의 행위에 준하는 일체의 결제 부정행위</li>
            </ul>
          </li>

          <li>4. 시스템 부정행위
            <ul>
              <li>- 회사의 서비스·시스템을 이용한 스팸 정보 전송 또는 악성코드 프로그램 유포 등의 범죄 및 불법행위</li>
              <li>- 회사의 소프트웨어를 무단으로 상업화하는 행위</li>
              <li>- 회사의 시스템에 비정상적인 방법으로 접근하거나 공격하는 행위</li>
              <li>- 회사의 정보·데이터를 정당한 권한 없이 스스로 또는 제3자를 통하여 복사, 퍼가기, 스크래핑하거나 기타의 방법으로 상업적으로 이용하는 행위</li>
              <li>- 회사의 서비스·시스템을 미수출 관리 법령과 게시된 규칙, 제한을 위반하여 수출 또는 재수출하는 행위</li>
              <li>- 위의 각호의 행위에 준하는 일체의 시스템 부정행위</li>
            </ul>
          </li>

          <li>5. 비매너 행위
            <ul>
              <li>- 서비스 및 거래와 관련하여 일방적인 요구를 지속하는 행위</li>
              <li>- 회원 간의 협박·욕설·비방·모욕 행위</li>
              <li>- 회사의 임직원·상담원·피용자·업무수탁자에게 모욕감을 줄 수 있는 행위</li>
              <li>- 위의 각호의 행위에 준하는 일체의 비매너·업무 방해 행위</li>
            </ul>
          </li>

          <li>6. 회사가 제공하는 문의·답변 기능을 악용하는 행위
            <ul>
              <li>- 특정 서비스 및 강의의 홍보행위</li>
              <li>- 구인·구직 등의 제안행위</li>
              <li>- 공공질서 및 미풍양속에 위반되는 내용 및 첨부파일을 발송하는 행위</li>
              <li>- 상대방의 업무를 방해할 목적으로 스팸성 메시지를 발송하는 행위</li>
              <li>- 위의 각호의 행위에 준하는 일체의 문의·답변 기능의 악용행위</li>
            </ul>
          </li>

          <li>7. 연락 두절·회피 행위
            <ul>
              <li>- 상대방의 문의에 대한 일체의 답변·응대가 진행되지 않는 경우</li>
              <li>- 진행 중인 강의에 대한 일체의 피드백·응대가 진행되지 않는 경우</li>
              <li>- 특정 회원의 문의에 대한 답변·응대를 의도적으로 회피함이 확인되는 경우</li>
              <li>- 회사의 연락에 대한 답변·응대를 의도적으로 회피함이 확인되는 경우</li>
              <li>- 위의 각 호의 행위에 준하는 일체의 무응답 행위</li>
            </ul>
          </li>

          <li>8. 매매부적합 서비스
            <ul>
              <li>- 상대방에게 매매부적합 서비스의 거래를 요청하는 행위</li>
              <li>- 회사의 시스템에 매매부적합 서비스를 판매 등록하는 행위</li>
              <li>- 기타 법령과 공공질서 및 미풍양속에 어긋나는 거래 행위</li>
              <li>- 위의 각호의 행위에 준하는 매매부적합 서비스의 문의·거래 행위</li>
            </ul>
          </li>

          <li>9. 권리침해
            <ul>
              <li>- 타인의 정보를 통해 서비스를 이용하는 행위</li>
              <li>- 제재 회피 등 부정한 목적으로 회원 정보의 수정을 거부하거나 해태한 경우</li>
              <li>- 거래의 제반 행위를 통해 저작권·초상권 등의 제 3자의 권리를 침해하는 경우</li>
              <li>- 회사의 개인정보처리방침 또는 관련 법령을 위배하여 다른 회원의 개인정보를 침해하는 행위</li>
              <li>- 타인의 명예 또는 신용, 정당한 이익을 훼손하는 행위</li>
              <li>- 회사의 명예 또는 신용, 정당한 이익을 훼손하는 행위</li>
              <li>- 위의 각호의 행위에 준하는 일체의 권리침해 행위</li>
            </ul>
          </li>
          <li>마. 회사는 그 외 약관 또는 법령에 위반되거나 위법·부당한 행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우, 서비스의 이용 제한 및 기타 필요한 조치를 취할 수 있습니다.</li>
          <li>바. 서비스 판매와 관련하여 본 조항에서 명시하지 않은 사항은 제23조(레벨업지지코치의 의무), 제24조(판매 서비스의 등록)를 따릅니다.</li>
        </ol>
        <h2>[23조] 레벨업지지 코치의 의무</h2>
        <ol>
          <li>가. 레벨업지지 코치는 관계 법령과 해당 서비스 약관, 회사가 서비스화면을 통하여 미리 공지하는 개별약관이 정하는 절차에 따라 신원정보의 제공, 판매 서비스의 등록, 거래 진행 과정의 관리, 수강생에 대한 거래이행, 강의 발송, A/S 또는 환불 등의 사후처리를 수행하여야 합니다. 각호의 행위를 위반할 경우 회사는 내부 규정에 따라 조치할 수 있으며, 이로 인한 모든 책임은 레벨업지지 코치에게 귀속됩니다.</li>
          <li>나. 판매 등록을 위해 제공한 신원정보의 명의자와 실제 서비스를 제공하는 레벨업지지 코치는 동일해야 합니다. 하도급 행위 등 본 조항에 따른 권리∙의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나 담보로 제공하는 행위가 적발될 경우 회사는 내부 규정에 따라 조치할 수 있으며, 이로 인한 모든 책임은 레벨업지지 코치에게 귀속됩니다.</li>
          <li>다. 레벨업지지 코치는 강의 금액·방식·내용 등 강의 조건에 대하여 서비스 페이지에 사실만을 기재하고, 예외적인 범위에 대해서는 수강생 회원과 사전에 합의하여야 합니다.
            <ul>
              <li>- 레벨업지지 코치가 스스로 제공할 수 있는 범위의 강의 조건을 명시해야 합니다.</li>
              <li>- 일부 또는 전부를 도급 및 위임해야 하는 강의의 경우, 회사가 승인하는 범위 내에서 서비스 페이지에 명시하며 추가로 수강생과 사전 합의를 진행해야 합니다.</li>
              <li>- 서비스에 명시된 강의 일을 초과하는 경우, 강의소요일에 대한 사전 합의를 진행해야 하며, 2차 발송일에 대해 사전 안내를 진행해야 합니다.</li>
            </ul>
          </li>
          <li>라. 레벨업지지 코치는 회원의 문의에 성실히 답변해야 합니다.</li>
          <li>마. 레벨업지지 코치는 거래 조건이 충족되지 않은 상태에서 수강생에게 강의 서비스를 제공하거나 구매 확정을 요청해서는 안 됩니다.</li>
          <li>바. 레벨업지지 코치는 예상치 못한 사유로 정상적인 강의 진행이 곤란한 경우, 지체 없이 수강생에게 거래 취소 사유의 안내 및 환급(에 필요한 조치) 등 관계 법령이 정한 조치를 취하여야 합니다. 또한 수강생으로부터 주문취소의 요청이 있는 경우 이에 응하여 접수된 주문 건에 대하여 24시간 이내 취소 의사와 함께 관련 사유를 수강생에게 전달하여야 합니다.</li>
          <li>사. 서비스 등록은 등록양식에 따라야 하며, 서비스 등록양식에 어긋난 등록이나 허위등록, 판매 가장등록, 신용카드결제시스템 또는 통신과금시스템만을 이용하기 위한 등록은 불가합니다.</li>
          <li>아. 레벨업지지 코치는 서비스등록 시 외부거래를 유도하는 문구나 수강생의 정당한 권리(청약 철회권 등)를 제한하거나, 허위 또는 기타 법령이나 약관에 위배되는 내용(문구, 사진 또는 설명을 포함)을 등록해서는 아니 되며, 스스로 또는 다른 회원을 통하여 고의로 서비스 평가, 판매 횟수 등을 수정·조작하거나 기타 판매 가장등록 등 중개서비스의 안전성과 신뢰성을 해하는 부정한 행위를 하여서도 안 됩니다.</li>
          <li>자. 레벨업지지 코치는 회사의 명칭, 로고 및 회원의 회원등급 표시와 회사가 제공한 메인 이미지 등을 회사가 정한 목적 이외 용도 및 장소에 사용하거나 표시해서는 안 되며, 이러한 행위로 인하여 회사 또는 타 회원에게 피해가 발생한 경우 이에 대하여 일체의 법적 책임을 부담하여야 합니다.</li>
          <li>차. 레벨업지지 코치는 회사의 레벨업지지 사이트, 모바일 레벨업지지에서의 거래와 관련하여 전자상거래 등에서의 소비자 보호에 관한 법률 등 관계 법령에서 명시한 자료를 보관하여야 합니다.</li>
          <li>카. 레벨업지지 코치는 자신의 레벨업지지 코치 정보란에 회사가 정하는 절차에 따라 인증받은 휴대폰 번호를 대표번호로써 설정하고 항상 최신 정보로서 유지하여야 합니다. 또한, 대표번호 변경 시 새로이 인증을 받아야 합니다.</li>
          <li>타. 레벨업지지 코치는 통신판매 중개 의뢰 및 그와 관련한 계약 이행 과정에서 알게 된 수강생 등 다른 회원의 개인 정보를 법률·약관 또는 회사의 개인정보처리방침에서 정한 목적 외의 용도로 사용할 수 없습니다. 회사의 고의 또는 과실과 무관하게 특정 레벨업지지 코치가 처리·처리 중인 다른 회원 또는 제3자의 개인 정보가 침해된 경우, 회사는 그에 대하여 책임을 지지 않으며 레벨업지지 코치가 자신의 노력과 비용으로 회사를 면책시켜야 합니다.</li>
          <li>파. 레벨업지지 코치는 판매 관련 정보(만족도, 메시지 응답률, 안심번호 응대율, 주문 성공률, 강의일 준수율, 알림·공지사항의 확인 등)를 지속해서 관리하여야 하며, 이를 반영하는 회원 등급 정책에 협조해야 합니다.</li>
          <li>하. 회사는 레벨업지지 코치가 등록한 서비스의 판매 촉진 및 신뢰도 상승을 위해 서비스의 메인 이미지 또는 프로필 이미지 등에 대한 등록 기준을 마련하여 레벨업지지 코치에게 서비스 수정을 요청할 수 있으며, 레벨업지지 코치는 이에 동의하여야 합니다.</li>
        </ol>
        <h2>[24조] 판매 서비스의 등록</h2>
        <ol>
          <li>가. 등록자격
          <li>서비스를 판매하고자 하는 회원은 이 약관에 따라 서비스를 등록할 수 있습니다. 다만, 회사는 소비자보호 및 서비스 수준 유지를 위해 서비스를 등록할 수 있는 레벨업지지 코치의 자격을 제한할 수 있습니다.</li>
          <li>나. 서비스의 등록</li>
          <li>1) 레벨업지지 코치는 판매서비스의 등록 시 수강생이 구매서비스에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게 기재하여야 하며, 회사가 승인하지 아니한 시스템을 통하여 회사 정보통신망에 무단 접속하여 자동으로 등록하는 방법으로 서비스를 등록할 수 없습니다. 회사는 서비스검색의 효율성, 시스템에 걸리는 부하 등을 고려하여 레벨업지지 코치에 대한 사전 통지로써 레벨업지지 코치 1인당 서비스 등록 건수를 제한할 수 있습니다. 레벨업지지 코치가 회사의 물품 등록건수 제한조치에 반하여 서비스등록을 한 경우 회사는 등록된 서비스를 중지하거나 삭제 할 수 있고 위반횟수 및 수준에 따라 해당 서비스를 등록한 레벨업지지 코치의 회원자격을 정지하거나 서비스 이용을 제한할 수 있습니다.
            <ul>
              <li>①코칭 목록 : 코치는 본인이 가능한 코칭목록을 중복 등록 할수 있습니다.</li>
              <li>②코치 정보 : 레벨업지지 코치는 프로필 이미지와 함께 실제 연락 가능한 번호 및 연락 가능한 시간을 작성해야 합니다. 또한 미결제 회원에게 안심번호 공개를 체크하여 결제 전의 레벨업지지 회원에게도 연락처를 공개할 수 있습니다. 안심번호의 공개는 의무사항이 아니며, 레벨업지지 코치는 이를 체크하지 않음으로써 미결제 회원에게 안심번호를 공개하지 않을 수 있습니다. 만일 안심번호 사용에 체크할 경우, 레벨업지지 코치는 본 약관 제20조 라.항에 동의한 것으로 간주됩니다. 또한 코치 정보에 대해서는 사실대로 명확하게 기재하여야 합니다. 실수 또는 착오 없이 거래하는 데 필요한 거래조건을 구체적으로 명시하여야 합니다. 허위 또는 과장 정보를 기재한 경우, 모순되는 내용의 정보가 기재되어 있는 경우, 이 약관에 반하는 거래조건이 기재된 경우 회사는 해당 거래를 취소하거나 판매중지 시킬 수 있습니다. 이 경우, 취소 또는 중지된 서비스에 관한 판매서비스이용료 및 상위노출광고서비스이용료는 환불되지 않으며, 중개의 취소 또는 중지로 인한 모든 위험과 책임은 판매서비스를 등록한 해당 회원이 부담해야합니다. 거래가 취소되거나 판매중지되면, 회사는 서비스화면 또는 메일(e-mail) 등의 방법을 통하여 해당 레벨업지지 코치와 수강생에게 그러한 사실을 통지합니다.</li>
              <li>③판매 가격 : 레벨업지지 코치는 판매 수수료와 부가세를 포함한 서비스의 기본 판매 단가를 명시하여야 합니다. 서비스의 판매 단가는 카테고리별 최저 단가부터 1,000원 단위로 설정할 수 있습니다. 향후 서비스 후기 및 코칭 정보에 따라 단가는 상승 제공 할 수 있습니다. 또한, 수강생에게 수수료 및 부가세 금액의 결제를 별도로 요청하실 경우에는 외부 거래 유도 행위·과도한 견적 요청 등으로 간주하여 조치가 취해질 수 있습니다.</li>
              <li>④배송비 : 레벨업지지 서비스는 배송 유형의 강의 판매가 아니기 때문에, 배송료는 발송되지 않습니다.</li>
              <li>⑤강의정보제공고시 : 레벨업지지 코치는 서비스 등록 시 품목별 재화 등에 관한 정보 및 거래 조건에 관한 정보 등 '전자상거래 등에서의 강의 등의 정보제공에 관한 고시'(공정거래위원회 고시 제2012-49호, 이하 '강의정보제공고시')에서 정한 정보를 입력해야 하고, 서비스 등록 후 강의정보제공고시가 변경되는 경우 그에 맞추어 관련 정보를 수정, 보완하여야 합니다. 레벨업지지 코치가 강의정보제공고시에 따른 정보를 입력하지 않거나, 서비스 등록 후 변경된 강의정보제공고시에 따라 정보를 수정, 보완하지 않는 경우 회사는 본 약관 23조에 따라 서비스 수정을 요청할 수 있으며, 22조에 따라 회원자격의 정지, 서비스 제공거부·제한 등 필요한 조치를 취할 수 있습니다.</li>
            </ul>
          </li>
          <li>2) 레벨업지지 코치는 등록서비스에 특별한 거래조건이 있거나 A/S, 교환, 반품비용 등 추가되는 비용이 있는 경우, 이를 수강생이 알 수 있도록 명확하게 기재하여야 합니다. 다만, 이러한 특별한 거래조건은 수강생의 정당한 권리를 제한하거나 이 약관을 위반하거나 기타 회사가 미리 정한 거래조건에 위배되어서는 안됩니다.</li>
          <li>3) 코치 등록 이후에도 수정이 가능합니다. 수정되거나 추가된 내용이 수강생에게 불리한 경우, 최종 수정되기 이전의 정보가 적용됩니다. 또한 승인받은 서비스를 성격·제공 방식 등이 다른 서비스로 변경하여 판매 데이터를 임의로 유지하는 경우, 회사는 판매 중단 등의 조치를 취할 수 있습니다.</li>
          <li>4) 일신의 문제로 인하여 판매가 불가능한 사유가 있는 경우에는 [강의상태] 항목을 비활성화으로써 일시적으로 판매를 중지할 수 있습니다.</li>
          <li>5) 레벨업지지 코치는 서비스 등록을 함에 있어서 저작권, 초상권 등 제3자의 권리를 침해해서는 안 됩니다. 레벨업지지 코치가 등록한 서비스 정보는 판매 활성화를 위하여 회사가 제휴한 사이트와 다른 회원의 블로그 등에 노출될 수 있습니다. 다만, 다른 회원의 블로그 등에의 노출은 다른 회원이 회사가 정한 이용규칙에 동의하고 회사가 허용한 방식으로 퍼가기 하는 경우에만 가능합니다.</li>
          <li>6) 레벨업지지 코치가 레벨업지지 코치 본인의 아이디 또는 다른 회원의 아이디를 이용하여 서비스 및 거래 조건이 실질적으로 동일한 서비스 ("동일 서비스")을 둘 이상 중복하여 등록하거나, 형식상 별개의 레벨업지지 코치임에도 회사가 사전에 고지하는 기준에 상응하는 동일 서비스를 등록할 수 없습니다. 이에 위반한 서비스 등록에 대해 회사는 서비스 검색의 효율성 제고 등을 위해 일정한 절차를 거쳐 중복 등록된 서비스를 판매중단 또는 삭제 처리할 수 있고 위반 횟수 및 수준에 따라 해당 서비스를 등록한 레벨업지지 코치의 회원자격을 정지 또는 서비스 이용을 제한하거나 인기도를 감점할 수 있습니다.</li>

          <li>다. 판매 서비스 등록정보 게재</li>
          <li>회사는 레벨업지지 코치가 등록한 서비스 등록정보를 회사가 정한 기준과 방법에 따라 레벨업지지 사이트 및 모바일 레벨업지지 에 게재합니다. 회사는 게재하는 서비스 등록정보의 위치, 크기, 배열 등을 결정하고 조정할 수 있으며, 이벤트 광고 등 회사의 서비스를 위하여 해당 서비스 화면을 구성, 편집하거나 서비스 등록 정보 외의 사항을 게재할 수 있습니다.</li>
        </ol>
        <h2>[안내] 부칙</h2>
        <p>본 약관은 2021년 4월 7일부터 시행됩니다.</p>
      </section>
      <img class="user-terms__under-logo" src="/img/header-nav/logo-fallback.png" alt="LVUP_logo">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTermsPage',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[service-terms-page] {
  padding: 90px 30px 120px;
  background-color: #ebebf0;
  .user-terms-con {
    max-width: 1280px;
    padding: 40px 40px 80px;
    border-radius: 30px;
    background-color: #fff;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.5);
    h1 {
      font-size: 20px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #bcbcbc;
      color: #000;
    }

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
      color: #000;
      margin-top: 60px;
      &:first-of-type {
        margin-top: 0;
      }
    }

    p, li {
      letter-spacing: -0.025em;
      margin-bottom: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      p, li {
        color: rgba(0, 0, 0, 0.4);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    li {
      p {
        margin-top: 15px;
      }
    }

    p + ol {
      padding-left: 10px;
    }

    ol {
      ul {
        margin-top: 15px;
        padding-left: 16px;
      }
    }

    a {
      color: #979797;
      word-break: break-all;
    }

    .terms-wide-table {
      th {
        width: 300px;
      }
    }

    .terms-table-wrap {
      th {
        background-color: #ebebf0;
      }
      ul {
        margin-top: 0;
      }
    }

    table {
      margin-top: 12px;
      border: 1px solid #d5d5de;
      border-radius: 12px;
    }
    th,
    td {
      padding: 16px;
      text-align: left;
      li:last-child {
        margin-bottom: 0;
      }
    }
  }
  .terms-wide-table {
    tr {
      th {
        border-bottom: 1px solid #d5d5de;
        border-right: 1px solid #d5d5de;
        color: rgba(0, 0, 0, 0.5);
      }
      td {
        border-bottom: 1px solid #d5d5de;
        color: rgba(0, 0, 0, 0.5);
      }
      &:last-child {
        th {
          border-bottom: 0;
        }
        td {
          border-bottom: 0;
        }
      }
    }
  }
  .user-terms__under-logo { .block; .w(68); .mt(64); .mh-c; }
  @media all and (min-width: 0) and (max-width: 767px) {
    padding: 53px 20px 80px;
    .user-terms-con {
      h2 {
        font-size: 13px;
        margin-top: 40px;
        margin-bottom: 22px;
      }
      p, li {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
    .user-terms-con {
      .terms-wide-table {
        th {
          width: 100px;
        }
      }
    }
  }
  @media all and (min-width: 0px) and (max-width: 479px) {
    .user-terms-con {
      margin-bottom: 80px;

      .terms-wide-table th {
        width: 100px;
      }
    }
  }
}
</style>
